<template>
  <div class="makeinvoice">
    <van-field name="radio" label="类型">
      <template #input>
        <van-radio-group v-model="checked" direction="horizontal">
          <van-radio name="C">个人</van-radio>
          <van-radio name="B">单位</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <div v-if="checked == 'C'">
      <van-form ref="form">
        <van-cell-group inset>
          <van-field
            v-model="FormComList.buyername"
            name="购方名称"
            label="购方名称"
            placeholder="请输入名称"
            :rules="[{ required: true, message: '请输入购方名称' }]"
          />
          <div class="buyerTaxpayerNum" style="display: flex">
            <van-field
              v-model="FormComList.buyertaxpayernum"
              name="识别码"
              label="识别码"
              placeholder="请输入个人输入身份证号"
            />
            <div class="search" v-if="runserial" @click="serchBillHead">
              <van-icon name="search" />
            </div>
          </div>

          <van-field
            v-model="FormComList.buyertel"
            name="手机号"
            label="手机号"
            placeholder="请输入您的联系电话"
            :rules="[
              { required: true, message: '请输入您的联系电话' },
              {
                pattern:
                  /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
                message: '请输入正确的电话号码'
              }
            ]"
          />
          <van-field
            v-model="FormComList.buyeremail"
            name="邮箱"
            label="邮箱"
            placeholder="邮箱地址，可用于接收电子发票"
            :rules="[
              { required: false, message: '请输入您的邮箱地址' },
              {
                pattern: /(^\b[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}\b$)|(^$)/,
                message: '请输入正确的邮箱地址格式'
              }
            ]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button block type="primary" @click="submitBill">
            确认
          </van-button>
        </div>
        <div style="margin: 16px">
          <van-button block color="#428bca" @click="backBilldetail">
            返回
          </van-button>
        </div>
      </van-form>
    </div>
    <div v-if="checked == 'B'">
      <van-form ref="comform">
        <van-cell-group inset>
          <van-field
            v-model="FormComList.buyername"
            name="购方名称"
            label="购方名称"
            placeholder="请输入名称"
            :rules="[{ required: true, message: '请输入购方名称' }]"
          />
          <div class="buyerTaxpayerNum" style="display: flex">
            <van-field
              v-model="FormComList.buyertaxpayernum"
              name="识别码"
              label="识别码"
              placeholder="请输入纳税人识别码"
              :rules="[{ required: true, message: '请输入识别码' }]"
            />
            <div class="search" v-if="runserial" @click="serchBillHead">
              <van-icon name="search" />
            </div>
          </div>
          <van-field
            v-model="FormComList.buyertel"
            name="电话"
            label="电话"
            placeholder="请输入联系电话"
            :rules="[
              { required: true, message: '请输入联系电话' }
              
            ]"
          />
          <van-field
            v-model="FormComList.buyeremail"
            name="邮箱"
            label="邮箱"
            placeholder="邮箱地址，可用于接收电子发票"
            :rules="[
              { required: false, message: '请输入邮箱地址' },
              {
                pattern: /^\b[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}\b$/,
                message: '请输入正确的邮箱地址格式'
              }
            ]"
          />
          <van-field
            v-model="FormComList.buyeraddress"
            name="地址"
            label="地址"
            placeholder="请输入地址"
            :rules="[{ required: true, message: '请输入公司地址' }]"
          />
          <van-field
            v-model="FormComList.buyerbankname"
            name="开户银行"
            label="开户银行"
            placeholder="请输入开户银行"
            :rules="[{ required: true, message: '请输入开户银行' }]"
          />
          <van-field
            v-model="FormComList.buyerbankaccount"
            name="账号"
            label="账号"
            placeholder="请输入银行账号"
            :rules="[{ required: true, message: '请输入银行账号' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" @click="submitComBill">
            确认
          </van-button>
        </div>
        <div style="margin: 16px">
          <van-button round block color="#428bca" @click="backBilldetail">
            返回
          </van-button>
        </div>
      </van-form>
    </div>

    <el-dialog title="发票抬头" :visible.sync="dialogTableVisible">
      <div
        class="shoubillhead"
        v-for="(item, index) in checkedList"
        :key="index"
        @click="makesure(index)"
      >
        <div v-if="item.buyertype == 'C'">
          <div class="table-head">
            <p>类型：个人</p>
          </div>
          <div class="table-content">
            <p>购方名称：{{ item.buyername }}</p>
            <p>识别码：{{ item.buyertaxpayernum }}</p>
            <p>手机：{{ item.buyertel }}</p>
            <p>Email：{{ item.buyeremail }}</p>
          </div>
        </div>
        <div v-if="item.buyertype == 'B'">
          <div class="table-head">
            <p>类型：公司</p>
          </div>
          <div class="table-content">
            <p>购方名称：{{ item.buyername }}</p>
            <p>识别码：{{ item.buyertaxpayernum }}</p>
            <p>电话：{{ item.buyertel }}</p>
            <p>Email：{{ item.buyeremail }}</p>
            <p>地址：{{ item.buyeraddress }}</p>
            <p>开户银行：{{ item.buyerbankname }}</p>
            <p>账号：{{ item.buyerbankaccount }}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: 'C',
      runserial: '',
      FormComList: {
        buyername: '',
        buyertaxpayernum: '',
        buyertel: '',
        buyeremail: '',
        buyeraddress: '',
        buyerbankname: '',
        buyerbankaccount: ''
      },
      dialogTableVisible: false,
      Mychecked: '',
      checkedList: [
        // {
        //   buyertype: 'B',
        //   buyername: '123',
        //   buyertaxpayernum: '12345667898987654',
        //   buyertel: '123',
        //   buyeremail: '123',
        //   buyeraddress: '123',
        //   buyerbankname: '123',
        //   buyerbankaccount: '123'
        // },
      ],
      mobile: ''
    }
  },
  created() {
    this.runserial = this.$route.query.runserial
    this.mobile = localStorage.getItem('mobile')
  },
  methods: {
    backBilldetail() {
      if (this.runserial) {
        this.$router.push('/index')
      } else {
        this.$router.push('/billhead')
      }
    },
    submitBill() {
      this.$refs.form
        .validate()
        .then(() => {
          if (this.runserial) {
            this.FormComList.runserial = this.runserial
            this.FormComList.buyertype = this.checked
            //具有流水号，开发票接口
            this.$axios
              .post(`create?mobile=${this.mobile}`, this.FormComList)
              .then((res) => {
                if (res.data.retcode == '0000') {
                  this.$dialog
                    .alert({
                      message: '开票成功'
                    })
                    .then(() => {
                      this.$router.push('/index')
                    })
                } else {
                  this.$dialog
                    .alert({
                      message: res.data.retmessage
                    })
                    .then(() => {
                      // on close
                    })
                }
              })
          } else {
            //新增发票抬头
            this.FormComList.infoid = ''
            this.FormComList.buyertype = this.checked
            this.$axios
              .post(`head?mobile=${this.mobile}`, this.FormComList)
              .then((res) => {
                if (res.data.retcode == '0000') {
                  this.$dialog
                    .alert({
                      message: '发票抬头创建成功'
                    })
                    .then(() => {
                      this.$router.push('/billhead')
                    })
                } else {
                  this.$dialog
                    .alert({
                      message: res.data.retmessage
                    })
                    .then(() => {
                      // on close
                    })
                }
              })
          }
        })
        .catch(() => {
          this.$dialog
            .alert({
              message: '请填写正确的内容'
            })
            .then(() => {
              // on close
            })
        })
    },
    submitComBill() {
      this.$refs.comform
        .validate()
        .then(() => {
          if (this.runserial) {
            //具有流水号，开发票接口
            this.FormComList.runserial = this.runserial
            this.FormComList.buyertype = this.checked
            this.$axios
              .post(`create?mobile=${this.mobile}`, this.FormComList)
              .then((res) => {
                if (res.data.retcode == '0000') {
                  this.$dialog
                    .alert({
                      message: '开票成功'
                    })
                    .then(() => {
                      // on close
                    })
                } else {
                  this.$dialog
                    .alert({
                      message: res.data.retmessage
                    })
                    .then(() => {
                      // on close
                    })
                }
              })
          } else {
            //新增发票抬头
            this.FormComList.infoid = ''
            this.FormComList.buyertype = this.checked
            this.$axios
              .post(`head?mobile=${this.mobile}`, this.FormComList)
              .then((res) => {
                if (res.data.retcode == '0000') {
                  this.$dialog
                    .alert({
                      message: '发票抬头创建成功'
                    })
                    .then(() => {
                      this.$router.push('/billhead')
                    })
                } else {
                  this.$dialog
                    .alert({
                      message: res.data.retmessage
                    })
                    .then(() => {
                      // on close
                    })
                }
              })
          }
        })
        .catch(() => {
          this.$dialog
            .alert({
              message: '请填写正确的内容'
            })
            .then(() => {
              // on close
            })
        })
    },
    serchBillHead() {
      this.$axios
        .get(
          `headsearch?mobile=${this.mobile}&buyername=${this.FormComList.buyername}`
        )
        .then((res) => {
          if (res.data.retcode) {
            this.checkedList = res.data.data
          } else {
            this.$dialog
              .alert({
                message: res.data.retmessage
              })
              .then(() => {
                // on close
              })
          }
        })
      this.dialogTableVisible = true
    },
    // cancel() {
    //   this.dialogTableVisible = false
    // },
    makesure(index) {
      if (this.checkedList[index].buyertype == 'C') {
        this.FormComList = this.checkedList[index]
        this.checked = 'C'
      }
      if (this.checkedList[index].buyertype == 'B') {
        this.FormComList = this.checkedList[index]
        this.checked = 'B'
      }
      this.dialogTableVisible = false
    }
  }
}
</script>
<style>
.makeinvoice {
  padding: 15px;
}
.makeinvoice .van-field__body {
  border: none;
}
.makeinvoice .van-field__label {
  width: 20%;
  margin-right: 0;
}
.makeinvoice .van-cell-group--inset {
  margin: 0;
}
.makeinvoice .buyerTaxpayerNum .van-cell {
  width: 88%;
  padding-right: 0;
}

.makeinvoice .search {
  width: 10%;
  text-align: center;
  line-height: 44px;
}
.makeinvoice .search .van-icon {
  display: inline-block;
  border: 1px solid #ccc;
  color: #ccc;
  padding: 5px;
  border-radius: 50%;
}
.makeinvoice .searchbillhead {
  max-height: 600px;
  position: relative;
}
.makeinvoice .searchbillhead .searchActive {
  padding: 15px;
  height: 500px;
  overflow-y: scroll;
}
.makeinvoice .searchbillhead .buttonStyle {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.makeinvoice .searchbillhead .buttonStyle button {
  width: 50%;
  border: none;
  color: #fff;
  font-size: 16px;
  line-height: 42px;
}
.makeinvoice .searchbillhead .buttonStyle .cancel {
  background: red;
}
.makeinvoice .searchbillhead .buttonStyle .makesure {
  background: rgb(12, 172, 12);
}
.makeinvoice .searchbillhead .van-tabs__content {
  padding: 0 15px;
}
.makeinvoice .searchbillhead .van-radio {
  height: 30px;
}

.makeinvoice .van-button--block {
  border-radius: 5px;
}
.van-field--error .van-field__control,
.van-field--error .van-field__control::placeholder {
  color: #c8c9cc;
}
.makeinvoice .el-dialog {
  width: 80%;
  max-height: 70%;
  overflow: scroll;
}
.makeinvoice .el-dialog .shoubillhead {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 10px;
}
.makeinvoice .el-dialog .shoubillhead .van-tag--primary {
  margin-left: 10px;
}

.makeinvoice .shoubillhead .table-head {
  display: flex;
  justify-content: space-between;
  background: #428bca;
  line-height: 30px;
  color: #fff;
  padding: 0 15px;
}
.makeinvoice .shoubillhead .table-content {
  background: #eee;
  padding: 15px;
}
</style>